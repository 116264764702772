<template>
  <TableTemplate
    :table-name="tableName"
    :table-columns="tableColumns"
    :use-language-selector="useLanguageSelector"
    :use-import="useImport"
  />
</template>

<script>
import TableTemplate from './TableTemplate';

export default {
  components: {
    TableTemplate
  },
  data() {
    return {
      tableName: 'currency',
      tableColumns: [
        {
          id: 'id',
          name: 'ID',
          allowEdit: false
        },
        {
          id: 'symbol',
          name: 'Symbol'
        },
        {
          id: 'name',
          name: 'Name'
        }
      ],
      useLanguageSelector: false,
      useImport: false
    };
  }
};
</script>
